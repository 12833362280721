import * as R from 'ramda';
import qs from 'qs';
import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import {
  // Access subjects
  DASHBOARD_ACCESS_SUBJECT,
  BILLING_ACCESS_SUBJECT,
  CRM_ACCESS_SUBJECT,
  ORDERS_ACCESS_SUBJECT,
  ORDERS_ACCESS_SUBJECT_DETAILS,
  PRODUCT_CONFIG_ACCESS_SUBJECT,
  PRODUCT_MANAGEMENT_ACCESS_SUBJECT,
  PRICING_MANAGEMENT_ACCESS_SUBJECT,
  TICKETING_ACCESS_SUBJECT,
  EMAIL_LOG_ACCESS_SUBJECT,
  CUSTOMIZATION_ACCESS_SUBJECT,
  EXTENSIBILITY_CENTER_ACCESS_SUBJECT,
  AMS_ACCESS_SUBJECT,
  WORKFLOW_ACCESS_SUBJECT,
  ROLES_ACCESS_SUBJECT,
  EQUIPMENT_RECORDS_ACCESS_SUBJECT,
  ADVANCED_SEARCH_ACCESS_SUBJECT,
  PROJECTS_ACCESS_SUBJECT,
  PAYMENT_PORTAL,
  REPORTS,
  INVOICE_TAX_LOGS,
  LOGS_ACCESS_SUBJECT,
  removeKeycloackHash,
} from '@/utils';

import {
  saveRouteToHistory,
} from './helpers';

export default function createRouter(ability) {
  const router = _createRouter({
    history: createWebHistory(process.env.BASE_URL),

    // Set custom query resolver
    // @see {@link https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/vue/}
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);

      return result || '';
    },

    routes: [
      // Temp router Will be deleted in main App
      {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "PageDashboard" */ '@modules/dashboard/pages/PageDashboard'),
        meta: {
          permission: DASHBOARD_ACCESS_SUBJECT,
          title: 'Dashboard',
        },
        beforeEnter: (to, from, next) => {
          if (!from.name) {
            router.push({ name: 'order' });
            return;
          }
          next();
        },
      },

      {
        path: '/customer',
        name: 'customer',
        component: () => import(/* webpackChunkName: "PageCustomer" */ '@modules/crm/pages/PageCustomer'),
        redirect: { name: 'organizations' },
        meta: {
          permission: CRM_ACCESS_SUBJECT,
          title: 'Billing',
        },
        children: [
          {
            path: 'organizations',
            name: 'organizations',
            component: () => import(/* webpackChunkName: "PageOrganizations" */ '@modules/crm/pages/PageOrganizations'),
            meta: {
              title: 'Organizations',
              parent: 'dashboard',
            },
          },
          {
            path: 'contacts',
            name: 'contacts',
            component: () => import(/* webpackChunkName: "PageContacts" */ '@modules/crm/pages/PageContacts'),
            meta: {
              title: 'Contacts',
              parent: 'dashboard',
            },
          },
        ],
      },

      // Tickets module
      {
        path: '/tickets',
        name: 'tickets',
        component: () => import(/* webpackChunkName: "PageTickets" */ '@modules/ticketing/pages/PageTickets'),
        meta: {
          permission: TICKETING_ACCESS_SUBJECT,
          title: 'Tickets',
          parent: 'dashboard',
        },
      },

      // Pricing module
      {
        path: '/pricing/models',
        name: 'pricing-models',
        component: () => import(/* webpackChunkName: "PagePricingModels" */ '@modules/pricing/pages/PagePricingModels'),
        meta: {
          title: 'Pricing Models List',
          permission: PRICING_MANAGEMENT_ACCESS_SUBJECT,
          parent: 'dashboard',
        },
      },

      {
        path: '/pricing/model-details/:uid',
        name: 'pricing-model-details',
        component: () => import(/* webpackChunkName: "PagePricingModelDetails" */ '@modules/pricing/pages/PagePricingModelDetails'),
        meta: {
          permission: PRICING_MANAGEMENT_ACCESS_SUBJECT,
          parent: 'pricing-models',
          title: 'Pricing Model Details',
        },
      },

      {
        path: '/pricing/model-details/:uid/element/:elementUid',
        name: 'pricing-element-details',
        component: () => import(/* webpackChunkName: "PagePricingElementDetails" */ '@modules/pricing/pages/PagePricingElementDetails'),
        meta: {
          permission: PRICING_MANAGEMENT_ACCESS_SUBJECT,
          parent: 'pricing-model-details',
          title: 'Pricing Element Details',
        },
      },

      // Product catalog module
      {
        path: '/product-catalog/products-list',
        name: 'products-list',
        component: () => import(/* webpackChunkName: "PageProducts" */ '@modules/product/pages/PageProducts'),
        meta: {
          title: 'Products List',
          permission: PRODUCT_MANAGEMENT_ACCESS_SUBJECT,
          parent: 'dashboard',
        },
      },

      {
        path: '/product-catalog/product-groups',
        name: 'product-groups',
        component: () => import(/* webpackChunkName: "PageProductGroups" */ '@modules/product/pages/PageProductGroups'),
        meta: {
          permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
          parent: 'dashboard',
          title: 'Groups',
        },
      },

      {
        path: '/product-catalog/product-attributes',
        name: 'product-attributes',
        component: () => import(/* webpackChunkName: "PageProductAttributes" */ '@modules/product/pages/PageProductAttributes'),
        meta: {
          permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
          parent: 'dashboard',
          title: 'Attributes',
        },
      },

      // Product config module
      {
        path: '/product-config/models',
        name: 'product-config',
        component: () => import(/* webpackChunkName: "PageProductModels" */ '@modules/product-config/pages/PageProductModels'),
        meta: {
          permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
          title: 'Models List',
          parent: 'dashboard',
        },
      },

      {
        path: '/product-config/model-details/:uid',
        name: 'model-details',
        component: () => import(/* webpackChunkName: "PageProductModelDetails" */ '@modules/product-config/pages/PageProductModelDetails'),
        meta: {
          permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
          parent: 'product-config',
          title: 'Model Details',
        },
      },

      // Order module
      {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "PageOrders" */ '@/modules/oms/pages/PageOrders'),
        meta: {
          permission: ORDERS_ACCESS_SUBJECT,
          title: 'Orders',
          parent: 'dashboard',
        },
      },
      {
        path: '/orders/:uid',
        name: 'orderEditState',
        component: () => import(/* webpackChunkName: "PageOrderState" */ '@/modules/oms/pages/PageOrderState'),
        meta: {
          permission: ORDERS_ACCESS_SUBJECT_DETAILS,
          title: 'Order',
          parent: 'order',
        },
      },

      // Billing module
      {
        path: '/billing',
        name: 'billing',
        component: () => import(/* webpackChunkName: "PageBilling" */ '@modules/billing/pages/PageBilling'),
        redirect: { name: 'ledger' },
        meta: {
          permission: BILLING_ACCESS_SUBJECT,
          title: 'Billing',
        },
        children: [
          {
            path: 'invoices',
            name: 'invoices',
            component: () => import(/* webpackChunkName: "PageInvoices" */ '@modules/billing/pages/PageInvoices'),
            meta: {
              title: 'Invoices',
              parent: 'dashboard',
            },
          },
          {
            path: 'ledger',
            name: 'ledger',
            component: () => import(/* webpackChunkName: "PageLedger" */ '@modules/billing/pages/PageLedger'),
            meta: {
              title: 'Ledger',
              parent: 'dashboard',
            },
          },
          {
            path: 'collections',
            name: 'collections',
            component: () => import(/* webpackChunkName: "PageCollections" */ '@modules/billing/pages/PageCollections'),
            meta: {
              title: 'Collections',
              parent: 'dashboard',
            },
          },
        ],
      },
      {
        path: '/billing/ledger/:uid/details',
        name: 'ledger-details',
        component: () => import(/* webpackChunkName: "PageCustomerLedgerDetails" */ '@modules/billing/pages/PageCustomerLedgerDetails'),
        meta: {
          parent: 'ledger',
          title: 'Ledger details',
        },
      },

      // VTickets module
      {
        path: '/vtickets',
        name: 'v-tickets-page',
        component: () => import(/* webpackChunkName: "PageVTickets" */ '@modules/v-tickets/pages/PageVTickets'),
        redirect: { name: 'v-tickets' },
        meta: {
          permission: BILLING_ACCESS_SUBJECT,
          title: 'VTickets',
          parent: 'dashboard',
        },
        children: [
          {
            path: 'tickets',
            name: 'v-tickets',
            component: () => import(/* webpackChunkName: "PageTickets" */ '@modules/v-tickets/pages/PageTickets'),
            meta: {
              title: 'VTickets',
              parent: 'dashboard',
            },
          },
          {
            path: 'tasks',
            name: 'v-tasks',
            component: () => import(/* webpackChunkName: "PageTasks" */ '@modules/v-tickets/pages/PageTasks'),
            meta: {
              title: 'VTasks',
              parent: 'dashboard',
            },
          },
        ],
      },

      // Workflow module
      {
        path: '/workflow',
        name: 'workflow',
        component: () => import(/* webpackChunkName: "PageWorkflows" */ '@modules/workflow/pages/PageWorkflows'),
        meta: {
          permission: WORKFLOW_ACCESS_SUBJECT,
          title: 'Workflows',
          parent: 'extensibility-center',
        },
      },
      {
        path: '/workflow/create/:selectedType',
        name: 'workflowCreate',
        component: () => import(/* webpackChunkName: "PageWorkflow" */ '@modules/workflow/pages/PageWorkflow'),
        meta: {
          permission: WORKFLOW_ACCESS_SUBJECT,
          title: 'Workflow',
          parent: 'workflow',
        },
      },
      {
        path: '/workflow/:uid',
        name: 'workflowEdit',
        component: () => import(/* webpackChunkName: "PageWorkflow" */ '@modules/workflow/pages/PageWorkflow'),
        meta: {
          permission: WORKFLOW_ACCESS_SUBJECT,
          title: 'Workflow',
          parent: 'workflow',
        },
      },

      // Logs module

      {
        path: '/logs',
        name: 'logs',
        component: () => import(/* webpackChunkName: "PageLogs" */ '@modules/logs/pages/PageLogs'),
        redirect: { name: 'email-logs' },
        meta: {
          permission: LOGS_ACCESS_SUBJECT,
          title: 'Logs',
        },
        children: [
          {
            path: 'email',
            name: 'email-logs',
            component: () => import(/* webpackChunkName: "PageEmailLog" */ '@modules/logs/pages/PageEmailLog'),
            meta: {
              permission: EMAIL_LOG_ACCESS_SUBJECT,
              title: 'Email Log',
              parent: 'dashboard',
            },
          },
          {
            path: 'invoice-tax',
            name: 'invoice-tax-logs',
            component: () => import(/* webpackChunkName: "PageInvoiceTaxLogs" */ '@modules/logs/pages/PageInvoiceTaxLogs'),
            meta: {
              title: 'Invoice tax logs',
              permission: INVOICE_TAX_LOGS,
              parent: 'dashboard',
            },
          },
          {
            path: 'payments',
            name: 'payments-logs',
            component: () => import(/* webpackChunkName: "PagePaymentsLogs" */ '@modules/logs/pages/PagePaymentsLogs'),
            meta: {
              title: 'Payments logs',
              permission: INVOICE_TAX_LOGS,
              parent: 'dashboard',
            },
          },
        ],
      },

      // Customization Center

      {
        path: '/customization-center',
        name: 'customization-center',
        component: () => import(/* webpackChunkName: "PageCustomizationCenter" */ '@modules/customization-center/pages/PageCustomizationCenter'),
        meta: {
          permission: CUSTOMIZATION_ACCESS_SUBJECT,
          title: 'Customization Center',
          parent: 'extensibility-center',
        },
      },

      // Roles & Permissions

      {
        path: '/roles',
        name: 'roles',
        component: () => import(/* webpackChunkName: "PageCustomizationCenter" */ '@modules/roles/pages/PageRoles'),
        meta: {
          permission: ROLES_ACCESS_SUBJECT,
          title: 'Roles & Permissions',
          parent: 'extensibility-center',
        },
      },

      // Extensibility center

      {
        path: '/extensibility-center',
        name: 'extensibility-center',
        component: () => import(/* webpackChunkName: "PageExtensibilityCenter/" */ '@modules/extensibility-center/pages/PageExtensibilityCenter'),
        meta: {
          permission: EXTENSIBILITY_CENTER_ACCESS_SUBJECT,
          title: 'Extensibility center',
          parent: 'dashboard',
        },
      },

      // AMS

      {
        path: '/ams',
        name: 'ams',
        component: () => import(/* webpackChunkName: "PageAms" */ '@modules/ams/pages/PageAms'),
        meta: {
          permission: AMS_ACCESS_SUBJECT,
          title: 'Access management service',
          parent: 'extensibility-center',
        },
      },

      // Equipment records module
      {
        path: '/equipment-records',
        name: 'equipment-records',
        component: () => import(/* webpackChunkName: "PageEquipmentRecords" */ '@modules/equipment-records/pages/PageEquipmentRecords'),
        meta: {
          title: 'Equipment Records',
          permission: EQUIPMENT_RECORDS_ACCESS_SUBJECT,
          parent: 'dashboard',
        },
      },

      {
        path: '/equipment-records/:uid',
        name: 'equipment-record',
        component: () => import(/* webpackChunkName: "PageEquipmentRecordDetails" */ '@modules/equipment-records/pages/PageEquipmentRecordDetails'),
        meta: {
          title: 'Equipment Record',
          permission: EQUIPMENT_RECORDS_ACCESS_SUBJECT,
          parent: 'equipment-records',
        },
      },

      // Projects module
      {
        path: '/projects',
        name: 'projects',
        component: () => import(/* webpackChunkName: "PageProjects" */ '@modules/projects/pages/PageProjects'),
        meta: {
          title: 'Projects',
          permission: PROJECTS_ACCESS_SUBJECT,
          parent: 'dashboard',
        },
      },

      // Invoicing

      {
        path: '/invoice-batches',
        name: 'invoicing',
        component: () => import(/* webpackChunkName: "PageAms" */ '@modules/invoicing/pages/PageInvoiceBatches'),
        meta: {
          permission: AMS_ACCESS_SUBJECT,
          title: 'Invoice Batches',
          parent: 'dashboard',
        },
      },

      {
        path: '/invoice-batches/:uid',
        name: 'invoiceBatchDetails',
        component: () => import(/* webpackChunkName: "PageAms" */ '@modules/invoicing/pages/PageInvoiceBatch'),
        meta: {
          permission: AMS_ACCESS_SUBJECT,
          title: 'Invoice Batch',
          parent: 'invoicing',
        },
      },

      // Reports
      {
        path: '/apxreports',
        name: 'apxreports',
        component: () => import(/* webpackChunkName: "PageReports" */ '@modules/reporting/pages/PageReports'),
        meta: {
          title: 'APX reports',
          permission: REPORTS,
          parent: 'dashboard',
        },
      },

      // Payment portal
      {
        path: '/payment-portal',
        name: 'payment-portal',
        component: () => import(/* webpackChunkName: "PagePaymentPortal" */ '@modules/payment-portal/pages/PagePaymentPortal'),
        meta: {
          title: 'Payment Portal',
          permission: PAYMENT_PORTAL,
          parent: 'dashboard',
        },
      },

      // Search engine
      {
        path: '/advanced-search',
        name: 'advanced-search',
        component: () => import(/* webpackChunkName: "Search-engine" */ '@modules/search-engine/pages/AdvancedSearch'),
        redirect: {
          name: 'mcr-search',
          params: {
            reportType: 'mcr',
          },
        },
        meta: {
          permission: ADVANCED_SEARCH_ACCESS_SUBJECT,
          title: 'Advanced search',
          parent: 'dashboard',
        },
        children: [
          {
            path: 'type/:reportType',
            name: 'mcr-search',
            component: () => import(/* webpackChunkName: "Search-engine-mcr" */ '@modules/search-engine/pages/MCRAdvancedSearch'),
            meta: {
              title: 'Advanced search',
              parent: 'dashboard',
            },
          },
        ],
      },

      // Errors
      {
        path: '/403',
        name: 'access-denied',
        component: () => import(/* webpackChunkName: "PageAccessDenied" */ '@/components/PageAccessDenied'),
        meta: {
          parent: 'dashboard',
          title: 'Access denied',
        },
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    const accessSubject = R.pathOr(undefined, ['meta', 'permission'], to);
    const parent = R.pathOr(undefined, ['meta', 'parent'], to);
    const isAddToHistory = R.pathOr(undefined, ['meta', 'isAddToHistory'], to);
    // TODO: fix abilities usage

    if (isAddToHistory && parent) {
      saveRouteToHistory(to.fullPath, parent);
    }

    if (accessSubject && ability.can('read', accessSubject) && to.name !== 'access-denied') {
      return next();
    }

    if (accessSubject && !ability.can('manage', accessSubject) && to.name !== 'access-denied') {
      return next('/403');
    }

    return next();
  });

  router.afterEach(() => {
    // this code for clear Keycloack state from hash
    // should check if Keycloack have solution
    window.location.hash = removeKeycloackHash(window.location.hash);
  });

  // eslint-disable-next-line
  router.backInHistory = () => {
    const parent = R.pathOr(undefined, ['meta', 'parent'], router.currentRoute);
    const isAddToHistory = R.pathOr(undefined, ['meta', 'isAddToHistory'], router.currentRoute);
    if (parent && isAddToHistory) {
      const history = store.getters['history/navigationHistory'](parent);
      if (history.length > 1) {
        const newRoute = history[1];
        store.dispatch('history/goBack', parent);
        router.push(newRoute);
      } else {
        // eslint-disable-next-line
        store.dispatch('history/goBack', parent);
        router.push({ name: parent });
      }
    }
  };

  return router;
}
